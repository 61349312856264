import dom from "../../../../common/ts/core/dom";


function init() {
    showLoginPopup();
}

const NAVBAR_ACCESS_ACCOUNT = "#navbar-access-account";

function showLoginPopup() {
    dom.select(NAVBAR_ACCESS_ACCOUNT)?.addEventListener('click', () => {
        drawLoginModal();
    });
}

function drawLoginModal(){
    fetch(`/jsp/postYourAd/personalAreaLogin.jsp`, {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ section: 97 }),
    })
        .then(response => response.text())
        .then(async (data) => {
            await loadGoogleJSLibrary();
            document.body.insertAdjacentHTML('afterbegin', data);
            document.querySelector(".popClose")?.addEventListener('click', () => {
                document.getElementById("popupLogin")?.remove()
            });
        })
        .catch(error => console.error(error));
}
async function loadGoogleJSLibrary() {
    $.getScript("https://apis.google.com/js/api:client.js", function() {
        // @ts-ignore
        gapi.load('auth2', function() {
            // @ts-ignore
            gapi.auth2.init({
                client_id: '63812571663-s203v3fpt91jpste9cm5hh95g472ja39.apps.googleusercontent.com',
                cookiepolicy: 'single_host_origin',
            });
        });
    });
}

export default {
    init,
}