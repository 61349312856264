function init() {
    selectPropertyType();
}



function selectPropertyType(){
    const propertyTypeLabelElements = document.querySelectorAll('.propertyTypeLabel');
    propertyTypeLabelElements.forEach((item) => {
        item.addEventListener('click', () => {
            const property_type_name_field = document.getElementById("propertyTypeName") as HTMLInputElement;
            property_type_name_field.value = item.innerHTML;
        })
    });
}

export default {
    init,
}