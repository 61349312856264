import {EventBus} from "../../../common/ts/events/EventBus";
import tracking from "../../../common/ts/core/tracking";
import {SECTION_CLICKED_EVENT_TYPE} from "../../../common/ts/tracking/constants";
import dom from "../../../common/ts/core/dom";

const FORM_ID = 'home-search-box-form';

function init(
    pageViewId: string = "",
    fromSerp: boolean = false,
) {
    const eventBus = EventBus.getInstance();
    const form: HTMLElement | null = document.getElementById(FORM_ID);
    form?.addEventListener('submit', function (event) {
        const geoIdInput: HTMLInputElement | null = document.querySelector('.inputLocation input[name="geoId"]');
        if (geoIdInput != null) {
            if (!geoIdInput.value) {
                event.preventDefault();
                eventBus.emit(new dom.ShowEvent({ selector: ".inputLocation .not-selected" }));
            } else if (fromSerp) {
                eventBus.emit(new tracking.TrackAmplitudeEvent({
                    type: SECTION_CLICKED_EVENT_TYPE,
                    serpUrl: window.location.href,
                    sectionName: "SECTION_SERP_HORIZONTAL_NAV_FORM",
                    section: "6009",
                    value: getValuesFromFormAsString(),
                    pageViewId,
                }));
            }
        }
    });
}

function getValuesFromFormAsString() {
    const formData = dom.getFormData(`#${FORM_ID}`);
    if (formData != null) {
        let values = [];
        if (formData.operationType) {
            values.push(`operationType=${formData.operationType}`);
        }
        if (formData.propertyType) {
            values.push(`propertyType=${formData.propertyType}`);
        }
        if (formData.geoId) {
            values.push(`geoId=${formData.geoId}`);
        }

        return values.join("&");
    }

    return "";
}

export default {
    init,
}
