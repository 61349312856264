import {WhitelabelEvent, WhitelabelEventWithPayload} from "../events/Events";
import {EventBus} from "../events/EventBus";

type entries = [string, any][]

let eventBus: EventBus;
let globalDocument: Document | object;

function select<ElementType extends Element = Element>(selector: string): ElementType | null {
    return (globalDocument as Document).querySelector(selector);
}

function selectAll(selector: string): Element[] {
    return Array.from((globalDocument as Document).querySelectorAll(selector));
}

function selectAllAsType<T extends Element>(selector: string): T[] {
    return Array.from((globalDocument as Document).querySelectorAll(selector));
}

function selectValue(selector: string): string | undefined {
    const element = select(selector) as HTMLInputElement
    return element?.value;
}

function getFormData(selector: string): {[key: string]: any} | null {
    const formData = getRawFormData(selector);

    if (formData == null) {
        return null;
    }

    return Object.fromEntries(formData.entries());
}
function getFormDataMultiSelection(selector: string): {[key: string]: any} | null {
    let formData = getRawFormData(selector);
    if (formData == null) {
        return null;
    }

    return Object.fromEntries(
        Array.from(formData.keys()).map(key =>
            [
                key, formData.getAll(key).length > 1 ?
                formData.getAll(key).toString() : formData.get(key)
            ]
        )
    )
}

function getRawFormData(selector: string): {[key: string]: any} | null {
    const formElement = select(selector);
    if(formElement == null) {
        return null;
    }

    const element = formElement as HTMLFormElement
    return new FormData(element);
}

interface AddSubmitListenerPayload {
    selector: string,
    listener: any,
}

export class AddSubmitListenerEvent implements WhitelabelEventWithPayload<AddSubmitListenerPayload> {
    payload: AddSubmitListenerPayload;
    type: string;
    static readonly TYPE: string = 'dom-add-submit-event-listener';

    constructor(payload: AddSubmitListenerPayload) {
        this.type = AddSubmitListenerEvent.TYPE;
        this.payload = payload;
    }
}

export function domAddSubmitEventListener(event: WhitelabelEvent) {
    const {payload: {selector, listener}} = event as AddSubmitListenerEvent;

    selectAll(selector)?.forEach(element => element.addEventListener('submit', listener))
}

interface AddClickListenerPayload {
    selector: string,
    listener: any,
}

export class AddClickListenerEvent implements WhitelabelEventWithPayload<AddClickListenerPayload> {
    payload: AddClickListenerPayload;
    type: string;
    static readonly TYPE: string = 'dom-add-click-event-listener';

    constructor(payload: AddClickListenerPayload) {
        this.type = AddClickListenerEvent.TYPE;
        this.payload = payload;
    }
}

export function domAddClickEventListener(event: WhitelabelEvent) {
    const {payload: {selector, listener}} = event as AddClickListenerEvent;

    selectAll(selector)?.forEach(element => element.addEventListener('click', listener))
}

interface UpdateDataAttributeEventPayload {
    elementSelector: string,
    attributeName: string,
    attributeValue: string,
}

export class UpdateDataAttributeEvent implements WhitelabelEventWithPayload<UpdateDataAttributeEventPayload> {
    payload: UpdateDataAttributeEventPayload;
    type: string;
    static readonly TYPE: string = 'update-data-attribute-event';

    constructor(payload: UpdateDataAttributeEventPayload) {
        this.type = UpdateDataAttributeEvent.TYPE;
        this.payload = payload;
    }
}

export function domUpdateDataAttributeEventHandler(event: WhitelabelEvent) {
    const {payload: { elementSelector, attributeName, attributeValue }} = event as UpdateDataAttributeEvent;
    select(elementSelector)?.setAttribute(attributeName, attributeValue);
}

interface SubmitFormPayload {
    selector: string,
}

export class SubmitFormEvent implements WhitelabelEventWithPayload<SubmitFormPayload> {
    payload: SubmitFormPayload;
    type: string;
    static readonly TYPE: string = 'dom-submit-form-requested';

    constructor(payload: SubmitFormPayload) {
        this.type = SubmitFormEvent.TYPE;
        this.payload = payload;
    }
}

export function domSubmitFormEventHandler(event: WhitelabelEvent) {
    const {payload: {selector}} = event as SubmitFormEvent;

    const formElement = select(selector) as HTMLFormElement;
    formElement.submit();
}

interface PopulateFormPayload {
    selector: string,
    data: entries
}

export class PopulateFormEvent implements WhitelabelEventWithPayload<PopulateFormPayload> {
    payload: PopulateFormPayload;
    type: string;
    static readonly TYPE: string = 'dom-populate-form-requested';

    constructor(payload: PopulateFormPayload) {
        this.type = PopulateFormEvent.TYPE;
        this.payload = payload;
    }
}

export function domPopulateFormEventHandler(event: WhitelabelEvent) {
    const {payload: {selector, data}} = event as PopulateFormEvent;

    const formElements = selectAll(selector) as HTMLFormElement[];

    if (formElements.length == 0) {
        return;
    }

    data.forEach(([name, value]) => {
        formElements.forEach(formElement => {
            // @ts-ignore
            const inputElement = formElement.elements[name];
            inputElement.value = value;
        })
    })
}

interface ToggleClassPayload {
    selector: string,
    className: string,
}

export class ToggleClassEvent implements WhitelabelEventWithPayload<ToggleClassPayload> {
    payload: ToggleClassPayload;
    type: string;
    static readonly TYPE: string = 'dom-toggle-className';

    constructor(payload: ToggleClassPayload) {
        this.type = ToggleClassEvent.TYPE;
        this.payload = payload;
    }
}

export function domToggleClassEventHandler(event: WhitelabelEvent) {
    const { payload: { selector, className } } = event as ToggleClassEvent;
    const element = select(selector) as HTMLElement;
    element.classList.toggle(className);
}

interface AddClassPayload {
    selector: string,
    className: string,
}

export class AddClassEvent implements WhitelabelEventWithPayload<AddClassPayload> {
    payload: AddClassPayload;
    type: string;
    static readonly TYPE: string = 'dom-add-className';

    constructor(payload: AddClassPayload) {
        this.type = AddClassEvent.TYPE;
        this.payload = payload;
    }
}

export function domAddClassEventHandler(event: WhitelabelEvent) {
    const { payload: { selector, className } } = event as AddClassEvent;
    const element = select(selector) as HTMLElement;

    if(element == null) {
        return;
    }

    element.classList.add(className);
}

interface RemoveClassPayload {
    selector: string,
    className: string,
}

export class RemoveClassEvent implements WhitelabelEventWithPayload<RemoveClassPayload> {
    payload: RemoveClassPayload;
    type: string;
    static readonly TYPE: string = 'dom-remove-className';

    constructor(payload: RemoveClassPayload) {
        this.type = RemoveClassEvent.TYPE;
        this.payload = payload;
    }
}

export function domRemoveClassEventHandler(event: WhitelabelEvent) {
    const { payload: { selector, className } } = event as RemoveClassEvent;
    const element = select(selector) as HTMLElement;
    element?.classList.remove(className);
}

interface AddStylesPayload {
    selector: string,
    styles: Partial<CSSStyleDeclaration>,
}

export class AddStylesEvent implements WhitelabelEventWithPayload<AddStylesPayload> {
    payload: AddStylesPayload;
    type: string;
    static readonly TYPE: string = 'dom-add-styles';

    constructor(payload: AddStylesPayload) {
        this.type = AddStylesEvent.TYPE;
        this.payload = payload;
    }
}

export function domAddStylesHandler(event: WhitelabelEvent) {
    const { payload: {selector, styles}} = event as AddStylesEvent;

    const elements = selectAll(selector);

    elements?.forEach(element => {
        const style = (element as HTMLElement).style;
        return Object.assign((element as HTMLElement).style, styles);
    });
}

interface ShowPayload {
    selector: string,
}

export class ShowEvent implements WhitelabelEventWithPayload<ShowPayload> {
    payload: ShowPayload;
    type: string;
    static readonly TYPE: string = 'dom-show';

    constructor(payload: ShowPayload) {
        this.type = ShowEvent.TYPE;
        this.payload = payload;
    }
}

export function domShowEventHandler(event: WhitelabelEvent) {
    const { payload: {selector}} = event as ShowEvent;

    const elements = selectAll(selector);

    elements?.forEach(element => (element as HTMLElement).style.display = '');
}

interface HidePayload {
    selector: string,
}

export class HideEvent implements WhitelabelEventWithPayload<HidePayload> {
    payload: HidePayload;
    type: string;
    static readonly TYPE: string = 'dom-hide';

    constructor(payload: HidePayload) {
        this.type = HideEvent.TYPE;
        this.payload = payload;
    }
}

export function domHideEventHandler(event: WhitelabelEvent) {
    const { payload: {selector}} = event as HideEvent;

    const elements = selectAll(selector);

    elements?.forEach(element => (element as HTMLElement).style.display = 'none');
}

interface SetContentPayload {
    selector: string,
    content: string,
}

export class SetContentEvent implements WhitelabelEventWithPayload<SetContentPayload> {
    payload: SetContentPayload;
    type: string;
    static readonly TYPE: string = 'dom-set-content';

    constructor(payload: SetContentPayload) {
        this.type = SetContentEvent.TYPE;
        this.payload = payload;
    }
}

export function domSetContentEventHandler(event: WhitelabelEvent) {
    const { payload: { selector, content } } = event as SetContentEvent;
    const elements = selectAll(selector);
    elements?.forEach(element => (element as HTMLElement).innerHTML = content);
}

interface SetAdjacentHTMLPayload {
    selector: string;
    html: string;
}

export class SetAdjacentHTMLEvent implements WhitelabelEventWithPayload<SetAdjacentHTMLPayload> {
    payload: SetAdjacentHTMLPayload;
    type: string;
    static readonly TYPE: string = 'set-adjacent-html';

    constructor(payload: SetAdjacentHTMLPayload) {
        this.type = SetAdjacentHTMLEvent.TYPE;
        this.payload = payload;
    }
}

export function domSetAdjacentHTMLEventHandler(event: WhitelabelEvent) {
    const { payload: { selector, html } } = event as SetAdjacentHTMLEvent;
    const container = select(selector);
    container?.insertAdjacentHTML("beforeend", html);
}

function init(doc: Document | object = document, ev: any = EventBus.getInstance()) {
    globalDocument = doc;
    eventBus = ev;
    eventBus.subscribe(AddClickListenerEvent.TYPE, domAddClickEventListener);
    eventBus.subscribe(AddSubmitListenerEvent.TYPE, domAddSubmitEventListener);
    eventBus.subscribe(SubmitFormEvent.TYPE, domSubmitFormEventHandler);
    eventBus.subscribe(PopulateFormEvent.TYPE, domPopulateFormEventHandler);
    eventBus.subscribe(ToggleClassEvent.TYPE, domToggleClassEventHandler);
    eventBus.subscribe(AddClassEvent.TYPE, domAddClassEventHandler);
    eventBus.subscribe(RemoveClassEvent.TYPE, domRemoveClassEventHandler);
    eventBus.subscribe(AddStylesEvent.TYPE, domAddStylesHandler);
    eventBus.subscribe(ShowEvent.TYPE, domShowEventHandler);
    eventBus.subscribe(HideEvent.TYPE, domHideEventHandler);
    eventBus.subscribe(SetContentEvent.TYPE, domSetContentEventHandler);
    eventBus.subscribe(SetAdjacentHTMLEvent.TYPE, domSetAdjacentHTMLEventHandler);
    eventBus.subscribe(UpdateDataAttributeEvent.TYPE, domUpdateDataAttributeEventHandler);
}

export default {
    select,
    selectAll,
    selectAllAsType,
    selectValue,
    getFormData,
    getFormDataMultiSelection,
    AddClickListenerEvent,
    AddSubmitListenerEvent,
    SubmitFormEvent,
    PopulateFormEvent,
    ToggleClassEvent,
    AddClassEvent,
    RemoveClassEvent,
    AddStylesEvent,
    ShowEvent,
    HideEvent,
    SetContentEvent,
    UpdateDataAttributeEvent,
    init,
}
